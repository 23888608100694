import axios from 'axios'
import {cache, buildUniqueUrl} from './cache'

// 防止重复请求
export default (options = {}) => async config => {
  const defaultOptions = {
    cacheTime: 0, // 缓存时间，设置为0，不清除缓存
    cache: false, // 是否支持缓存
    noWarn: false, // 是否有提示
    ...options
  }
  const index = buildUniqueUrl(config.url,config.method,config.params,config.data)
  let responsePromise = cache.get(index)
  if (!responsePromise || !defaultOptions.cache) {
    responsePromise = (async () => {
      try {
        const response = await axios.defaults.adapter(config)
        return Promise.resolve(response)
      } catch (reason) {
        cache.clear(index)
        if (defaultOptions.noWarn) reason.noWarn = true
        return Promise.reject(reason)
      }
    })()
    cache.set(index, responsePromise)
    if (defaultOptions.cacheTime !== 0) {
      setTimeout(() => {
        cache.clear(index)
      }, defaultOptions.cacheTime)
    }
  }
  return responsePromise.then(data => JSON.parse(JSON.stringify(data))) // 为防止数据源污染
}
