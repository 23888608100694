import Vue from 'vue'
import Vuex from 'vuex'
import commonData from './common-data/module'
import report from './pages/report/module'
import home from './pages/home/module'
import illList from './pages/consultation/module'
import exam from './pages/ai-doctor/module'
import locationData from './pages/my-location/module'
import appointment from './pages/appointment-information/module'
import hospital from './pages/select-institutions/module'
import im from './im/module'
import online_chat from './pages/online-chat/module'
import no_read from './pages/article-no-read-comments/module'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    commonData,
    report,
    home,
    illList,
    exam,
    im,
    locationData,
    appointment,
    hospital,
    online_chat,
    no_read
  }
})
