import api from '../../utils/api'


export async function recommend_info (id, payload) {
  return api.post(`/api/v1/mp/triage/recommend/${id}`, payload)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/triage/recommend/${id}`, payload)
}

export async function get_specificity (payload) {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         history: [
  //           {
  //             id: 1,
  //             text: '是否怀孕',
  //             answer: false
  //           },
  //           {
  //             id: 2,
  //             text: '是否有以下症状',
  //             answer: true
  //           },
  //           {
  //             id: 3,
  //             text: '是否年纪超过1000',
  //             answer: false
  //           },
  //           {
  //             id: 4,
  //             text: '是否幼儿',
  //             answer: true
  //           },
  //         ],
  //         question: {
  //           id: 6,
  //           text: '第一个问题是否有新的症状等等',
  //           Y: {
  //             type: 2, //1继续 2返回重新选择 3结束
  //             message: '重大疾病'
  //           },
  //           N: {
  //             type: 1, //1继续 2返回重新选择 3结束
  //             message: ''
  //           }
  //         }
  //       }
  //     })
  //   }, 1000)
  // })
  return api.get(`/api/v1/mp/triage/questionnaire/${payload}/specificity`)
}

export async function get_next (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         question: {
  //           id: 8,
  //           text: '是否有大的新的症状等等',
  //           Y: {
  //             type: 2, //1继续 2返回重新选择 3结束
  //             message: '重大疾病'
  //           },
  //           N: {
  //             type: 3, //1继续 2返回重新选择 3结束
  //             message: ''
  //           }
  //         }
  //       }
  //     })
  //   }, 1000)
  // })
  return api.post(`/api/v1/mp/triage/questionnaire/${payload.question_id}/specificity/next/${payload.id}`, payload.answer)
}

export async function is_pregnancy (id, payload) {
  return api.post(`/api/v1/mp/triage/${id}/pregnancy`, payload)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/triage/recommend/${id}`, payload)
}

export async function comments (id, payload) {
  return api.post(`/api/v1/mp/triage/comment/${id}`, payload)
}

export async function get_comments (id) {
  return api.get(`/api/v1/mp/triage/comment/${id}`)
}

export async function get_order_id (payload) {
  return api.post(`/api/v1/mp/orders/`, payload)
}



