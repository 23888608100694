import axios from "axios"
import qs from "qs"
import { Notify } from "vant"
import { getSession } from "./session"
import defaultAdapter from "./defaultAdapter"
import { Dialog } from "vant"
import router from "../router"
import { encrypt, decrypt } from "./aes"

// 只显示一个错误提示
let networkError = false
const warn = (message) => {
  if (!message || networkError) return
  networkError = true
  Notify({
    type: "danger",
    message,
    onClose() {
      networkError = false
    },
  })
}

export const baseURL = GLOBAL_CONFIG.backend_server
export const CancelToken = axios.CancelToken // 取消请求

const api = axios.create({
  baseURL,
  timeout: 0,
})

// 添加请求拦截器
api.interceptors.request.use((config) => {
  // 带上用户token
  const authToken = getSession()
  if (authToken) {
    config.headers["Authorization"] = authToken.token
  }

  // 清除不需要的参数
  if (typeof config.params === "object") {
    config.params = JSON.parse(JSON.stringify(config.params)) // 清除与原数据的引用关联
    for (const i in config.params) {
      if (config.params[i] === undefined || config.params[i] === null || config.params[i] === "") {
        delete config.params[i]
      }
    }
  }
  config.paramsSerializer = (params) => {
    return qs.stringify(params, { arrayFormat: "comma" })
  }

  if (!config.defineAdapter) {
    config.adapter = defaultAdapter({
      cacheTime: 1000,
      cache: true,
      ...config.defaultAdapterOptions,
    })
  }
  // 加密
  if (config.data) {
    config.data = encrypt(config.data)
    config.headers["Content-Type"] = "application/json"
  }

  return config
})

// 退出
export const logout = () => {
  // 自建
  if (GLOBAL_CONFIG.source === 1) {
    Dialog.alert({
      title: "提示",
      message: "您需要重新登录才能继续使用",
      confirmButtonText: "重新登录",
    }).then(() => {
      router.push({ path: "/login" })
    })
  }
  // 罗湖
  else if (GLOBAL_CONFIG.source === 2) {
    router.push({ path: "/login" })
  }
}

// 添加响应拦截器
api.interceptors.response.use(
  (response) => {
    // 解密
    return { data: decrypt(response.data) }
  },
  (err) => {
    if (err && err.response) {
      // 解密
      if (err.response.data) err.response.data = decrypt(err.response.data)
      switch (err.response.status) {
      case 400:
        err.message = "请求错误"
        break

      case 401:
        err.message = "未授权，请登录"
        logout()
        if ([1, 2].includes(GLOBAL_CONFIG.source)) return
        break

      case 403:
        err.message = "拒绝访问"
        logout()
        break

      case 404:
        err.message = `请求地址出错: ${err.response.config.url}`
        break

      case 408:
        err.message = "请求超时"
        break

      case 500:
        err.message = "服务器内部错误"
        break

      case 501:
        err.message = "服务未实现"
        break

      case 502:
        err.message = "网关错误"
        break

      case 503:
        err.message = "服务不可用"
        break

      case 504:
        err.message = "网关超时"
        break

      case 505:
        err.message = "HTTP版本不受支持"
        break

      default:
      }
    } else if (err.message === "Network Error") {
      console.log("网络错误，请稍后再试！")
      err.message = ""
    }
    if (err && err.response && err.response.data && err.response.data.message) {
      err.message = err.response.data.message
    }
    if (!err.noWarn) warn(err.message)
    return Promise.reject(err)
  }
)

export default api
