import Vue from "vue"
import Router from "vue-router"
import { Dialog } from "vant"
import { removeSession, getSession } from "./utils/session"
import { logout } from "./pages/login/service"
// import { im_logout } from './im'
import { noLoginArr, need_card_arr } from "./pages/login/config"

/* Global */
import CommonLayout from "./pages/common-layout"

const Im = () => import("./im/demo.vue")
const Demo = () => import("./pages/demo")
const Login = () => import("./pages/login")

const Home = () => import("./pages/home")
const Appointment = () => import("./pages/appointment")
const MyOrder = () => import("./pages/my-order")
const MyCheck = () => import("./pages/my-check")
const MyInspection = () => import("./pages/my-inspection")

const MyDetailProcess = () => import("./pages/my-details")

const MyCheckDetail = () => import("./pages/my-check-detail")
const LaboratoryDetail = () => import("./pages/laboratory-detail")
const DiagnosisDetail = () => import("./pages/diagnosis-detail")
const LabList = () => import("./pages/lab-list")
const Report = () => import("./pages/report")
const MyReport = () => import("./pages/my-report")
const OrderDeatil = () => import("./pages/order-detail")
const LabDetail = () => import("./pages/lab-detail")
const OrderSuccess = () => import("./pages/order-success")
const OnlineInquiry = () => import("./pages/online-inquiry")
const OnlineInquiryDetail = () => import("./pages/online-inquiry-detail")
const OnlineComments = () => import("./pages/online-comments")
const OnlinePay = () => import("./pages/online-pay")
const OnlineChat = () => import("./pages/online-chat")
const OnlineCommentRate = () => import("./pages/online-comment-rate")
const OnlineChatList = () => import("./pages/online-chat-list")
const MyLocation = () => import("./pages/my-location")
const EditLocation = () => import("./pages/edit-location")
const EditMap = () => import("./pages/edit-map")
const ElectronicRescription = () => import("./pages/electronic-rescription")
const InspectionOrder = () => import("./pages/inspection-order")
const RiskStatement = () => import("./pages/article")

const PersonalCenter = () => import("./pages/personal-center")
const Card = () => import("./pages/card")
const NoCard = () => import("./pages/no-card")
const Empty = () => import("./pages/empty")
const Consultation = () => import("./pages/consultation")
const Aidoctor = () => import("./pages/ai-doctor")
const PreOrder = () => import("./pages/pre-order")
const Inspection = () => import("./pages/inspection")
const ContraindicationEvaluation = () => import("./pages/contraindication-evaluation")
const AnesthesiaResult = () => import("./pages/anesthesia-result")
const AddressList = () => import("./pages/anesthesia-result/address-list")
const EditAddress = () => import("./pages/anesthesia-result/edit-address")
const SubmitSuccess = () => import("./pages/anesthesia-result/submit-success")
// 支付
const Pay = () => import("./pages/pay")
const PayCancel = () => import("./pages/pay-cancel")
const PaySuccess = () => import("./pages/pay-success")

const Web = () => import("./pages/web")
const PageAlert = () => import("./pages/page-alert")
const Risk = () => import("./pages/risk")
const Health = () => import("./pages/health-check")
const HealthForm = () => import("./pages/health-check-form")
const HealthResult = () => import("./pages/health-check-result")

const Registered = () => import("./pages/registered/health-card")
const MyFamily = () => import("./pages/registered/my-family")
const Success = () => import("./pages/registered/success")
const Fail = () => import("./pages/registered/fail")
const MyPersonal = () => import("./pages/registered/personal")

// 预约挂号
const SelectInstitutions = () => import("./pages/select-institutions")
const SelectDepartment = () => import("./pages/select-department")
const SelectDoctor = () => import("./pages/select-doctor")
const DoctorPage = () => import("./pages/doctor-page")
const AppointmentInformation = () => import("./pages/appointment-information")
const AppointmentSuccess = () => import("./pages/appointment-success")
const RegistrationRecord = () => import("./pages/registration-record")
const RegistrationDetail = () => import("./pages/registration-detail")

//历史消息
const HistoryMessage = () => import("./pages/history-message")
//提醒详情
const AlertDetail = () => import("./pages/alert-detail")

// 健康档案
const HealthRecord = () => import("./pages/health-record")
const HealthRecordAll = () => import("./pages/health-record/all")
const HealthRecordExam = () => import("./pages/health-record-exam")
const HealthRecordLab = () => import("./pages/health-record-lab")
const HealthRecordHistory = () => import("./pages/health-record/medical-history")
const HealthRecordDiagnose = () => import("./pages/health-record-diagnose")
const HealthRecordMedication = () => import("./pages/health-record-medication")
const AddAllergicHistory = () => import("./pages/add-allergic-history")
const AddFamilyHistory = () => import("./pages/add-family-history")
const AddPersonHistory = () => import("./pages/add-person-history")
// 肠镜
const MyColonoscopyDetail = () => import("./pages/my-colonoscopy-detail")
//肠镜跳转页面
const Colonoscopy = () => import("./pages/colonoscopy")
const MockArticle = () => import("./pages/mock-article")

// 白名单添加
const WhiteListCode = () => import("./pages/white-list-code")

// 扫码模拟发送
const SendDemoMessage = () => import("./pages/send-demo-message")

// 微服务
const GaugeH5 = () => import("./micro/gauge_h5.vue")

// 推广码
const Qrcode = () => import("./pages/qrcode")
//评论
const ArticleNoReadComments = () => import("./pages/article-no-read-comments")

// 落地页
const Landing = () => import("./pages/landing")

// 数字人
const DigitalHuman = () => import("./pages/digital-human/index.vue")
// 二维码
const QrcodeDemo = () => import("./pages/qrcode-demo")
// 长海引导页
const GuideChanghai = () => import("./pages/guide-changhai/index.vue")

//视频
const VideoList = () => import("./pages/video/list.vue")
const Video = () => import("./pages/video/index.vue")

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: "/im",
      name: "IM例子",
      component: Im,
    },
    {
      path: "/demo",
      name: "例子",
      component: Demo,
    },
    {
      path: "/login",
      name: "登录",
      component: Login,
    },
    {
      path: "/landing",
      name: "落地页",
      component: Landing,
    },
    {
      path: "/guide-changhai",
      name: "长海医院",
      component: GuideChanghai,
    },
    {
      path: "/",
      component: CommonLayout,
      children: [
        {
          path: "",
          name: "首页",
          component: Home,
        },
        {
          path: "/personal-center",
          name: "个人中心",
          component: PersonalCenter,
        },
        {
          path: "/online-chat-list",
          name: "消息",
          component: OnlineChatList,
        },
        {
          path: "/page-alert",
          name: "提醒",
          component: PageAlert,
        },
      ],
    },
    {
      path: "/appointment",
      name: "预约",
      component: Appointment,
    },
    {
      path: "/my-order",
      name: "我的订单",
      component: MyOrder,
    },
    {
      path: "/order-detail",
      name: "订单详情",
      component: OrderDeatil,
    },

    {
      path: "/my-check",
      name: "我的检查",
      component: MyCheck,
    },
    {
      path: "/my-inspection",
      name: "我的检验",
      component: MyInspection,
    },
    {
      path: "/my-check-detail",
      name: "预约检验检查",
      component: MyCheckDetail,
    },
    {
      path: "/my-detail-process",
      name: "查看详情",
      component: MyDetailProcess,
    },
    {
      path: "/my-colonoscopy-detail",
      name: "评估结果",
      component: MyColonoscopyDetail,
    },
    {
      path: "/mock-article",
      name: "模拟发送",
      component: MockArticle,
    },
    {
      path: "/order-success",
      name: "预约成功",
      component: OrderSuccess,
    },

    {
      path: "/my-report",
      name: "我的报告",
      component: MyReport,
    },
    {
      path: "/report",
      name: "报告",
      component: Report,
    },
    {
      path: "/laboratory-detail",
      name: "检验解读",
      component: LaboratoryDetail,
    },
    {
      path: "/diagnosis-detail",
      name: "疾病解读",
      component: DiagnosisDetail,
    },
    {
      path: "/lab-list",
      name: "异常指标",
      component: LabList,
    },
    {
      path: "/lab-detail",
      name: "检验项详情",
      component: LabDetail,
    },
    {
      path: "/online-inquiry",
      name: "在线问诊",
      component: OnlineInquiry,
    },
    {
      path: "/online-inquiry-detail",
      name: "医生详情",
      component: OnlineInquiryDetail,
    },
    {
      path: "/online-comments",
      name: "全部评论",
      component: OnlineComments,
    },
    {
      path: "/online-pay",
      name: "支付",
      component: OnlinePay,
    },
    {
      path: "/online-chat",
      name: "聊天",
      component: OnlineChat,
      meta: {
        title_name: " ",
      },
    },
    {
      path: "/online-comment-rate",
      name: "评价",
      component: OnlineCommentRate,
    },
    {
      path: "/card",
      name: "电子健康卡",
      component: Card,
    },
    {
      path: "/no-card",
      name: "无健康卡",
      component: NoCard,
    },
    {
      path: "/empty",
      name: "敬请期待",
      component: Empty,
    },
    {
      path: "/consultation",
      name: "选择症状",
      component: Consultation,
    },
    {
      path: "/ai-doctor",
      name: "极速问诊",
      component: Aidoctor,
    },
    {
      path: "/pre-order",
      name: "确认订单",
      component: PreOrder,
    },
    {
      path: "/anesthesia-result",
      name: "结果评估",
      component: AnesthesiaResult,
    },
    {
      path: "/address-list",
      name: "我的收货地址",
      component: AddressList,
    },
    {
      path: "/edit-address",
      name: "编辑地址",
      component: EditAddress,
    },
    {
      path: "/submit-success",
      name: "申请订单",
      component: SubmitSuccess,
    },
    {
      path: "/contraindication-evaluation",
      name: "禁忌症评估",
      component: ContraindicationEvaluation,
    },
    {
      path: "/inspection",
      name: "选择项目",
      component: Inspection,
    },
    {
      path: "/pay",
      name: "支付订单",
      component: Pay,
    },
    {
      path: "/pay-cancel",
      name: "支付取消",
      component: PayCancel,
    },
    {
      path: "/pay-success",
      name: "支付成功",
      component: PaySuccess,
    },
    {
      path: "/my-location",
      name: "我的收货地址",
      component: MyLocation,
    },
    {
      path: "/edit-location",
      name: "编辑收货地址",
      component: EditLocation,
    },
    {
      path: "/edit-map",
      name: "编辑地址",
      component: EditMap,
    },
    {
      path: "/electronic-rescription",
      name: "电子处方单",
      component: ElectronicRescription,
    },
    {
      path: "/article",
      name: `${GLOBAL_CONFIG.hospital_name}健康服务平台`,
      component: RiskStatement,
    },
    {
      path: "/inspection-order",
      name: "检验检查单",
      component: InspectionOrder,
    },
    {
      path: "/web",
      name: "网页",
      component: Web,
    },
    {
      path: "/gauge-*",
      name: "量表",
      component: GaugeH5,
    },
    {
      path: "/risk",
      name: "健康风险",
      component: Risk,
    },
    {
      path: "/health-check",
      name: "体检",
      component: Health,
    },
    {
      path: "/health-check-form",
      name: "风险测算",
      component: HealthForm,
    },
    {
      path: "/health-check-result",
      name: "风险结果",
      component: HealthResult,
    },
    {
      path: "/health-card",
      name: "绑定健康卡",
      component: Registered,
    },
    {
      path: "/my-family",
      name: "我的家人 ",
      component: MyFamily,
    },
    {
      path: "/success",
      name: "绑定成功",
      component: Success,
    },
    {
      path: "/fail",
      name: "绑定失败",
      component: Fail,
    },
    {
      path: "/my-personal",
      name: "个人信息",
      component: MyPersonal,
    },
    //预约检查
    {
      path: "/select-institutions",
      name: "预约挂号",
      component: SelectInstitutions,
    },
    {
      path: "/select-department",
      name: "选择科室",
      component: SelectDepartment,
    },
    {
      path: "/select-doctor",
      name: "选择医生",
      component: SelectDoctor,
    },
    {
      path: "/doctor-page",
      name: "医生主页",
      component: DoctorPage,
    },
    {
      path: "/appointment-information",
      name: "预约信息",
      component: AppointmentInformation,
    },
    {
      path: "/appointment-success",
      name: "预约成功",
      component: AppointmentSuccess,
    },
    {
      path: "/registration-record",
      name: "挂号记录",
      component: RegistrationRecord,
    },
    {
      path: "/registration-detail",
      name: "预约详情",
      component: RegistrationDetail,
    },
    {
      path: "/history-message",
      name: "历史消息",
      component: HistoryMessage,
    },
    {
      path: "/health-record",
      name: "健康档案",
      component: HealthRecord,
      children: [
        {
          path: "medical-history",
          name: "健康档案-病史",
          component: HealthRecordHistory,
        },
        {
          path: ":type",
          name: "健康档案",
          component: HealthRecordAll,
        },
      ],
    },
    {
      path: "/health-record-exam",
      name: "健康档案-检查",
      component: HealthRecordExam,
    },
    {
      path: "/health-record-lab",
      name: "健康档案-检验",
      component: HealthRecordLab,
    },
    {
      path: "/health-record-diagnose",
      name: "健康档案-诊断",
      component: HealthRecordDiagnose,
    },
    {
      path: "/health-record-medication",
      name: "健康档案-用药",
      component: HealthRecordMedication,
    },
    {
      path: "/add-allergic-history",
      name: "添加过敏史",
      component: AddAllergicHistory,
    },
    {
      path: "/add-family-history",
      name: "添加家族史",
      component: AddFamilyHistory,
    },
    {
      path: "/add-person-history",
      name: "添加个人史",
      component: AddPersonHistory,
    },
    {
      path: "/alert-detail",
      name: "提醒详情",
      component: AlertDetail,
    },
    {
      path: "/colonoscopy",
      name: "预约肠镜",
      component: Colonoscopy,
    },
    {
      path: "/white-list-code",
      name: "小会体验用户申请",
      component: WhiteListCode,
    },

    {
      path: "/send-demo-message",
      name: "模拟发送",
      component: SendDemoMessage,
    },
    {
      path: "/qrcode",
      name: "罗湖人民医院公众号",
      component: Qrcode,
    },
    {
      path: "/article-no-read-comments",
      name: "历史消息记录",
      component: ArticleNoReadComments,
    },
    {
      path: "/digital-human",
      name: "数字人",
      component: DigitalHuman,
      meta: {
        title_name: "长海健康服务数字人",
      },
    },
    {
      path: "/qrcode-demo",
      name: "二维码",
      component: QrcodeDemo,
    },
    {
      path: "/video-list",
      name: "视频专栏",
      component: VideoList,
    },
    {
      path: "/video",
      // name: '视频专栏',
      component: Video,
    },
  ],
})

// 地址是否被包含
export const path_includes = (path, verify_list) => {
  if (path === "/login") return true
  for (let key of verify_list) {
    if (key.includes("*")) {
      key = key.replace("*", ".*")
    }
    const reg = new RegExp(`^${key}$`)
    if (reg.test(path)) {
      return true
    }
  }
  return false
}

router.beforeEach(async (to, from, next) => {
  const { path, fullPath, query } = to
  let redirect = encodeURIComponent(fullPath)

  // // 路由拦截-罗湖
  // if (GLOBAL_CONFIG.source === 2) {
  //   // 首页
  //   if (path === '/') {
  //     location.href = GLOBAL_CONFIG.luohu_url.home
  //     return
  //   }
  // }

  const authToken = getSession()
  // url直接传token登录
  const token = query.token
  if (path !== "/login" && token && !authToken) {
    router.push({ path: "/login", query: { redirect, token } })
    return
  }

  // 不需要登录验证的地址
  for (let i = 0, len = noLoginArr.length; i < len; i++) {
    if (noLoginArr[i] === path) {
      return next()
    }
  }

  const verifyArr = (authToken && authToken.verifyArr) || []
  // 需登录
  if (!path_includes(path, verifyArr)) {
    if (GLOBAL_CONFIG.source === 1) {
      Dialog.confirm({
        title: "提示",
        message: "您尚未登录，请先登录后访问",
        confirmButtonText: "立刻登录",
        cancelButtonText: "再等等",
      })
        .then(() => {
          router.push({ path: "/login", query: { redirect } })
        })
        .catch(() => {
          // on cancel
        })
    } else {
      router.push({ path: "/login", query: { redirect } })
    }
    return
  }

  // 需绑卡
  if (path !== "/login" && path_includes(path, need_card_arr) && authToken && !authToken.has_card) {
    Dialog.confirm({
      title: "提示",
      message: "您未绑定健康卡，部分功能不能使用",
      confirmButtonText: "立刻绑卡",
      cancelButtonText: "再等等",
    })
      .then(() => {
        router.push({ path: "/health-card" })
      })
      .catch(() => {
        // on cancel
      })
    return
  }

  if (path === "/login" && authToken) {
    try {
      await logout()
      // 退出im
      // await im_logout()
    } finally {
      removeSession()
    }
  }
  next()
})

export default router
