<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
    };
  },
  watch: {
    $route() {
      this.change_title();
    },
  },
  components: {
  },
  destroyed() {
  },
  created() {
    this.change_title();
  },
  methods: {
    change_title() {
      if (this.$route.name !== "网页") {
        const {name, meta} = this.$route
        document.title = meta.title_name || name || '首页'
      }
    },
  },
};
</script>

<style scoped>
#app {
  height: 100%;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
}
</style>
