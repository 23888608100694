// 数据格式化
import format from "date-fns/format"

// 完整时间：yyyy-MM-dd HH:mm:ss
export function format_date(timestamp, str = "yyyy-MM-dd") {
  return timestamp && format(+timestamp, str)
}

// 获取年纪
export function format_birthDay(b) {
  if (b) {
    let birthday = new Date(format_date(b).replace(/-/g, "/"))
    let d = new Date()
    return (
      d.getFullYear() -
        birthday.getFullYear() -
        (d.getMonth() < birthday.getMonth() ||
        (d.getMonth() === birthday.getMonth() && d.getDate() < birthday.getDate())
          ? 1
          : 0) || 0
    )
  }
}

// 处理健康卡
export function format_medical_card(card, start = 4, howmany = 4, replace = "****") {
  if (card) {
    const rs = [...card]
    rs.splice(start, howmany, replace)
    return rs.join("")
  }
}

// 小数转百分比
export function format_decimal(number = 0, num = 2, scale = 100) {
  return +(+number * scale).toFixed(num)
}

// 处理金额
export function format_money(number = 0, num = 2, scale = 100) {
  return (+number / scale).toFixed(num)
}

// 随时生成id
export function create_guid() {
  return (
    (Math.random() * 10000000).toString(16).substr(0, 4) +
    "-" +
    new Date().getTime() +
    "-" +
    Math.random().toString().substr(2, 5)
  )
}

// 数组转标签
export function arr_label(arr, props) {
  props = {
    label: "label",
    value: "value",
    children: "children",
    re: true,
    ...props,
  }
  const obj = {}
  function re(arr) {
    if (arr && arr.length > 0) {
      for (let item of arr) {
        obj[item[props.value]] = item[props.label]
        if (props.re) re(item[props.children])
      }
    }
  }
  re(arr)
  return obj
}

// 数组转对象
export function arr_obj(arr, props) {
  props = {
    value: "value",
    children: "children",
    re: true,
    ...props,
  }
  const obj = {}
  function re(arr) {
    if (arr && arr.length > 0) {
      for (let item of arr) {
        obj[item[props.value]] = item
        if (props.re) re(item[props.children])
      }
    }
  }
  re(arr)
  return obj
}

// url参数
export function get_url_params(url = location.href) {
  let data = {}
  if (url.includes("?")) {
    let query = url.split("?")[1]
    let vars = query.split("&")
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=")
      data[pair[0]] = pair[1]
    }
  }
  return data
}

// 判断安卓
export function isAndroid() {
  var u = navigator.userAgent
  if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
    return true
  }
  return false
}

// 判断设备为 ios
export function isIos() {
  var u = navigator.userAgent
  if (u.indexOf("iPhone") > -1 || u.indexOf("iOS") > -1) {
    return true
  }
  return false
}

// 判断设备为移动端
export function is_mobile() {
  var u = navigator.userAgent
  if (
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|Opera Mini)/i.test(
      u
    )
  ) {
    return true
  }
  return false
}

//date复制
Date.prototype.clone = function () {
  return new Date(this.valueOf())
}

export const format_duration = (num, hour = true) => {
  if (!num) return "00:00:00"
  const h = Math.floor(num / (60 * 60)),
    m = Math.floor((num % (60 * 60)) / 60),
    s = num - h * 60 * 60 - m * 60
  const hh = h >= 10 ? h : "0" + h,
    mm = m >= 10 ? m : "0" + m,
    ss = s >= 10 ? s : "0" + s
  return hour || h ? `${hh}:${mm}:${ss}` : `${mm}:${ss}`
}
