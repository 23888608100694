
//import { store_consultation } from "./service";

export default {
  namespaced: true,
  state: {
    user: {},
    illList: []
  },
  mutations: {
    clearState (state) {
      state.user = {}
      state.illList = []
    },
    updateUser(state,payload){
      state.user = payload
    },
    updateIll(state, payload) {
      state.illList = payload
    },
    show_loading (state) {
      state.loading = true
    },
    hide_loading (state) {
      state.loading = false
    },
  },
  actions: {
    // async store_page({ commit }, payload) {
    //   console.log(payload)
    //   try {
    //     commit('show_loading')
    //     const { data } = await store_consultation({ parts: payload });
    //     const parts_length = payload.length;
    //     let arr = []
    //     for (let i = 0; i < parts_length; i++) {
    //       let all = payload[i].symptoms.filter((item) => {
    //         return item.exist;
    //       })
    //       arr = arr.concat(all)
    //     }
    //     commit({
    //       type: 'updateIll',
    //       arr
    //     })
    //     // this.$router.push({ path: "/ai-doctor", query: { id: data.id, pregnancy: '' } });
    //   } finally {
    //     commit('hide_loading')
    //   }
    // },
    
  }
}