import {get_report} from './service'
export default {
  namespaced: true,
  state: {
    data: null,
    loading: false
  },
  mutations: {
    clearState (state) {
      state.data = null
      state.loading = false
    },
    updateData(state,payload){
      state.data = payload.data
    },
    show_loading (state) {
      state.loading = true
    },
    hide_loading (state) {
      state.loading = false
    },
  },
  actions: {
    async search_report({ commit }, payload) {
      try {
        commit('show_loading')
        const {data} = await get_report({id:payload})
        if(data) {
          let diagnosis = data.system_risk.diagnosis || []
          data.system_risk.diagnosis = diagnosis.filter(i=>{return i.reliable>0})
          commit({
            type: 'updateData',
            data
          })
        }
      } finally {
        commit('hide_loading')
      }

    }
  }
}
