
export default {
  namespaced: true,
  state: {
    data_obj: null, // 保存跳转页面参数
  },
  mutations: {
    save_action (state, payload) {
      state.data_obj = payload
    },
    clearState(state) {
      state.data_obj = null
    }
  },
  actions: {

  }
}
