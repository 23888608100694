// 系统标识
let system_flag = 'huihao_citizen'

export function set_system_flag (flag ) {
  system_flag = flag
}

export function get_system_key (key) {
  return `${system_flag}_${key}`
}


// 用户信息
export function removeSession (key = 'session') {
  localStorage.removeItem(get_system_key(key))
}

export function setSession (session, key = 'session') {
  localStorage.setItem(get_system_key(key), JSON.stringify(session))
}

export function getSession (key = 'session') {
  return JSON.parse(localStorage.getItem(get_system_key(key)))
}


// 安泰授权码
export function removeSession_at (key = 'session_at') {
  localStorage.removeItem(get_system_key(key))
}

export function setSession_at (session, key = 'session_at') {
  localStorage.setItem(get_system_key(key), JSON.stringify(session))
}

export function getSession_at (key = 'session_at') {
  return JSON.parse(localStorage.getItem(get_system_key(key)))
}
