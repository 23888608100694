
export default {
  namespaced: true,
  state: {
    location: {
      recipient: '',
      phone: '',
      address: '',
      house_num: '',
      default_address: false
    }, 
    
  },
  mutations: {
    set_location (state, payload) {
      state.location = payload
    },
    clear_state (state) {
      state.location = {
        recipient: '',
        phone: '',
        address: '',
        house_num: '',
        default_address: null
      }
    },
    
  },
  actions: {
    
  }
}
