import api from '../../utils/api'

// 登录
export async function login (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         name: '张三',
  //         token: 'eyJ1c2VyX2lkIjoxLCJleHBpcmVfdGltZSI6MTYxMTY1MDc5ODUxMn0.sOU12Rv6lTloal_pTTp_oWJ_QpE'
  //       }
  //     })
  //   }, 500)
  // })
  // /api/v1/mp/luohu/mock/session
  return api.post('/api/v1/mp/luohu/users/session', payload)
}

// 参数登录
export async function token_login (payload) {
  return api.post('/api/v1/mp/luohu/users/session', payload)
}

// 退出
export async function logout (payload) {
  console.log(payload)
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: null
      })
    }, 500)
  })
  // return api.delete('/api/v1/user/login')
}

// 获取当前绑定健康卡信息
export async function medical_cards () {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         name: null,
  //         qr_code: null,
  //       }
  //     })
  //   }, 500)
  // })
  return api.get('/api/v1/mp/medical_cards/defaults')
}
