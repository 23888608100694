export default {
  namespaced: true,
  state: {
    hospital_info: {},
    order_page: {}
  },
  mutations: {
    clearState (state) {
      state.hospital_info = {}
      state.order_page = {}
    },
    updateHospital(state, payload) {
      state.hospital_info = payload
    },
    updateOrderPage(state, payload) {
      state.order_page = payload
    },
  },
  actions: {
  }
}