<template>
  <div class="msg_load" v-if="msg_loading">
    <div class="point"></div>
    <div class="point s2"></div>
    <div class="point s3"></div>
  </div>
</template>
<script>
export default {
  name: 'MsgLoading',
  props: {
    msg_loading: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
    }
  },
}
</script>
<style scoped>
.msg_load {
  width: 80px;
  height: 33px;
  padding: 0 15px;
  background: #fff;
  border-radius: 17px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.point {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #1890ff;
  animation: colorChange 1.1s ease-in-out infinite;
  opacity: .1;

  &.s2 {
    animation-delay: .3s;
  }

  &.s3{
    animation-delay: .6s;
  }
}

@keyframes colorChange {
  0% {
    opacity: .2;
  }
  50% {
    opacity: .8;
  }
  100% {
    opacity: .2;
  }
}
</style>
