
//import { store_consultation } from "./service";

export default {
  namespaced: true,
  state: {
    no_read: false
  },
  mutations: {
    clearState (state) {
      state.no_read = false
    },
    update_no_read(state,payload){
      state.no_read = payload
    }
  }
}
