// 不需要登录验证的地址
export const noLoginArr = ["/demo", "/im", "/", "/qrcode", "/mock-article", "/qrcode-demo", "/guide-changhai"]

// 不需要权限验证的地址
export const noVerifyArr = [
  ...noLoginArr,
  "/appointment",
  "/my-order",
  "/order-detail",
  "/my-check",
  "/my-inspection",
  "/my-report",
  "/my-check-detail",
  "/my-detail-process",
  "/my-colonoscopy-detail",
  "/order-success",
  "/report",
  "/laboratory-detail",
  "/diagnosis-detail",
  "/lab-list",
  "/lab-detail",
  "/online-inquiry",
  "/online-inquiry-detail",
  "/online-comments",
  "/online-pay",
  "/online-chat",
  "/online-comment-rate",
  "/online-chat-list",
  "/card",
  "/empty",
  "/consultation",
  "/ai-doctor",
  "/pre-order",
  "/pay",
  "/pay-success",
  "/pay-cancel",
  "/no-card",
  "/inspection",
  "/personal-center",
  "/my-location",
  "/edit-location",
  "/edit-map",
  "/electronic-rescription",
  "/inspection-order",
  "/web",
  "/article",
  "/gauge*",
  "/page-alert",
  "/risk",
  "/health-check",
  "/health-check-form",
  "/health-check-result",
  "/my-family",
  "/success",
  "/fail",
  "/health-card",
  "/my-personal",
  "/select-institutions",
  "/select-department",
  "/select-doctor",
  "/doctor-page",
  "/appointment-information",
  "/appointment-success",
  "/registration-record",
  "/registration-detail",
  "/history-message",
  "/health-record*",
  "/alert-detail",
  "/add-allergic-history",
  "/add-family-history",
  "/add-person-history",
  "/my-inspection",
  "/contraindication-evaluation",
  "/anesthesia-result",
  "/address-list",
  "/edit-address",
  "/submit-success",
  "/colonoscopy",
  "/white-list-code",
  "/send-demo-message",
  "/article-no-read-comments",
  "/landing",
  "/digital-human",
  "/video-list",
  "/video",
]

// 有权限验证的地址
export const verifyArr = [...noVerifyArr]

// 需要绑定健康卡的地址
export const need_card_arr = ["/consultation", "/inspection", "/select-institutions", "/online-inquiry"]
