export default {
  namespaced: true,
  state: {
    loading: false,
    is_login: false, // 是否登录
    is_sdk_ready: false, // sdk是否准备好
    user_id: null, // 当前用户id
    conversation_list: [], // 会话列表
    all_conversation_data: {}, // 所有会话数据
    conversation_last_show_times: {}, // 会话最新展示时间
    page_size: 15, // 消息每页条数
  },
  mutations: {
    showLoading (state) {
      state.loading = true
    },
    hideLoading (state) {
      state.loading = false
    },
    update_login_state(state, payload) {
      state.is_login = payload
    },
    update_sdk_state(state, payload) {
      state.is_sdk_ready = payload
    },
    update_user_id(state, payload) {
      state.user_id = payload
    },
    update_conversation_list (state, payload) {
      state.conversation_list = payload
    },
    update_all_conversation_data (state, payload) {
      state.all_conversation_data = payload
    },
    update_conversation_last_show_times (state, payload) {
      state.conversation_last_show_times = payload
    },
  },
  actions: {
  }
}
