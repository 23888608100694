import {recommend_info, comments} from './service'
export default {
  namespaced: true,
  state: {
    exam_lab: [],
    data: {},
    loading: false,
    show_conmmend: false,
    like: true,
    value: '',
    lab_group: [],
  },
  mutations: {
    clearState (state) {
      state.exam_lab = []
      state.lab_group = []
      state.data = {}
      state.loading = false
      state.show_conmmend = false
    },
    updateExam(state, payload) {
      state.exam_lab = payload
    },
    updateGroup(state, payload) {
      state.lab_group = payload
    },
    updateOptional(state, payload) {
      state.optional_all = payload
    },
    updateData(state,payload){
      state.data = payload.data
    },
    updateComment(state,payload){
      state.data = payload.data
    },
    show_loading (state) {
      state.loading = true
    },
    hide_loading (state) {
      state.loading = false
      //state.show_conmmend = true
    },
    show_rest(state) {
      state.show_conmmend = true
    },
  },
  actions: {
    async get_recommend({ commit }, payload) {
      try {
        commit('show_loading')
        const { data } = await recommend_info(payload.id, {
          pregnancy: payload.pregnancy,
        })
        commit({
          type: 'updateData',
          data,
        })
      } finally {
        commit('hide_loading')
        commit('show_rest')
      }
    },
    async submit_like({ commit }, payload) {
      commit('show_loading')
      try {
        await comments(payload.id, {
          like: payload.like,
          comment: payload.value
        })
      } finally {
        commit('hide_loading')
      }
    },
    
  }
}