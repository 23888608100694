import api from '../../utils/api'

// 授权
export async function get_report (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data:{
  //         patient_name: '患者1',
  //         patient_age: 55,
  //         patient_gender: '男',
  //         analysisTime: new Date(),
  //         laboratories: [
  //           {
  //             laboratory_id: 1,
  //             laboratory_name: '化验名称',
  //             standard_units: 'ml',
  //             standard_result: '阳性',
  //             standard_result_type: 1,
  //             standard_specimen: '标本',
  //             abnormal: true,
  //             standard_reference_interval:'[1.015~1.025]'
  //           },
  //           {
  //             laboratory_id: 2,
  //             laboratory_name: '化验名称',
  //             standard_units: 'ml',
  //             standard_result: '叙述性结果德国海地共和国搭建国际化的更好的孤独感回家的感觉的广东省更好的时光会打电话感动好感动好感动好',
  //             standard_result_type: 3,
  //             standard_specimen: '标本',
  //             abnormal: true,
  //             standard_reference_interval:'[1.015~1.025]',
  //           },
  //           {
  //             laboratory_id: 3,
  //             laboratory_name: '化验名称',
  //             standard_units: 'ml',
  //             standard_result: '123',
  //             standard_result_type: 2,
  //             standard_specimen: '标本',
  //             abnormal: true,
  //             standard_reference_interval:'[1.015~1.025]',
  //             standard_normalized_quantitative: 12
  //           }
  //         ],
  //         system_risk: {
  //           systems: [{"full_name":"神经系统","name":"神经系统","rate":0.3},{"full_name":"消化系统","name":"消化系统","rate":0},{"full_name":"泌尿系统","name":"泌尿系统","rate":0.0},{"full_name":"血液系统","name":"血液系统","rate":0},{"full_name":"五官","name":"五官","rate":0.0},{"full_name":"免疫风湿","name":"免疫风湿","rate":0.0},{"full_name":"呼吸系统","name":"呼吸系统","rate":0.0},{"full_name":"肿瘤","name":"肿瘤","rate":0},{"full_name":"肌肉骨骼","name":"肌肉骨骼","rate":0.0},{"full_name":"循环系统","name":"循环系统","rate":0.0},{"full_name":"内分泌","name":"内分泌","rate":0.0},{"full_name":"感染","name":"感染","rate":0},{"full_name":"皮肤","name":"皮肤","rate":0.0},{"full_name":"生殖系统","name":"生殖系统","rate":0.0},{"full_name":"妊娠","name":"妊娠","rate":0.0}],
  //           diagnosis: [
  //             {
  //               name: '疾病一',
  //               rate: 0.5,
  //               is_right: true,
  //               reliable: 1,
  //               sub_types:['肺癌','结直肠癌','dhhdh'],
  //               source: 1
  //             },
  //             {
  //               name: '疾病二',
  //               rate: 0.9,
  //               is_right: true,
  //               reliable: 2,
  //               sub_types:['肺癌','结直肠癌','dhhdh'],
  //               source: 2
  //             }
  //           ]
  //         },
  //         id: 11,
  //         create_mini_user_id: 1223,
  //       }
  //     })
  //   }, 1000)
  // })
  return api.get(`/api/v1/mp/reports/analysis`,{params:payload})
}
