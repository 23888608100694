<template>
  <div class="view-wrapper">
    <div class="view-content">
      <router-view />
    </div>
    <van-tabbar route :fixed="false" v-model="active" v-if="[1, 2].includes(GLOBAL_CONFIG.source)">
      <van-tabbar-item icon="wap-home-o" replace to="/">首页
      </van-tabbar-item>
      <van-tabbar-item icon="chat-o" replace to="/online-chat-list">问诊
      </van-tabbar-item>
      <van-tabbar-item  replace to="/page-alert" icon="notes-o">提醒
      </van-tabbar-item>
      <van-tabbar-item replace to="/personal-center" icon="user-o">我的
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>

export default {
  data () {
    return {
      active: 1,
      GLOBAL_CONFIG,
    }
  },
  computed: {

  },
  watch: {
  },
  components: {
  },
  created () {
  },
  updated(){
    let dom = document.getElementsByClassName('view-content')[0]
    dom.scrollTo(0,0)
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .view-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.09);


    & .van-tabbar-item {
      position: relative;
      font-size: 9px;
    }

    & .van-tabbar-item--active {
      color: #0088FF;
    }
  }
  .view-content {
    flex: 1;
    overflow: auto;
  }
</style>
