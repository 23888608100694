export default {
  namespaced: true,
  state: {
    appointment_info: null,
  },
  mutations: {
    clearState (state) {
      state.appointment_info = {}
    },
    updateAppointment(state, payload) {
      state.appointment_info = payload
    },
    
  },
  actions: {
    
  }
}