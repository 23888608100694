// 定位
export async function geolocation() {
  return new Promise((resolve) => {
    try {
      if (GLOBAL_CONFIG.geolocation === false) {
        resolve({
          lat: 30.55485405609421,
          lng: 104.05895342248208,
        })
      } else {
        AMap.plugin("AMap.Geolocation", function () {
          let geolocation = new AMap.Geolocation({
            // 是否使用高精度定位，默认：true
            enableHighAccuracy: false,
            // 设置定位超时时间，默认：无穷大
            timeout: 3000,
          })

          geolocation.getCurrentPosition()
          AMap.event.addListener(geolocation, "complete", complete)
          AMap.event.addListener(geolocation, "error", error)

          function complete(data) {
            resolve({
              lat: data.position.lat,
              lng: data.position.lng,
            })
          }
          function error() {
            resolve({
              lat: null,
              lng: null,
            })
          }
        })
      }
    } catch (error) {
      resolve({
        lat: 30.55485405609421,
        lng: 104.05895342248208,
      })
    }
  })
}
