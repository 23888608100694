import Vue from "vue"
import Vant from "vant"
import "vant/lib/index.less"
import "video.js/dist/video-js.css"
import "./reset.css"

import App from "./App"
import router from "./router"
import store from "./store"
import Loading from "./components/Loading.vue"
import MsgLoad from "./components/im-basic/MsgLoading.vue"
import { get_url_params } from "./utils/format"
import { setSession } from "./utils/session"
import { verifyArr } from "./pages/login/config"

// 是否有g_token，模拟登录
let url_params = get_url_params()
if (url_params.g_token) {
  setSession({
    has_card: true,
    token: url_params.g_token,
    verifyArr,
  })
}
// 非罗湖
else if (![1, 2].includes(GLOBAL_CONFIG.source) && GLOBAL_CONFIG.wenfuyi_config.token) {
  setSession({
    has_card: true,
    token: GLOBAL_CONFIG.wenfuyi_config.token,
    verifyArr,
  })
}

Vue.config.productionTip = false

Vue.use(Vant)
// 全局注册loading
Vue.component(Loading.name, Loading)
Vue.component(MsgLoad.name, MsgLoad)

// 获取定位
store.dispatch("commonData/get_geolocation")

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
